<template>
    <div class="chat-quote-message">
		<div v-if="isRecall" class="recall">引用内容已撤回</div>
        <div v-if="!isRecall" class="send-user">
            {{ showName + ":" }}
        </div>
        <div v-if="!isRecall" class="quote-content">
            <span v-if="msgInfo.type == $enums.MESSAGE_TYPE.TEXT" v-html="$emo.transform(msgInfo.content)"></span>
            <div v-if="msgInfo.type == $enums.MESSAGE_TYPE.IMAGE">
                <img class="quote-image" :src="contentData.thumbUrl" @click.stop="showFullImageBox"/>
            </div>
            <div v-if="msgInfo.type == $enums.MESSAGE_TYPE.VIDEO">
                <video ref="videoPlayer" class="quote-video" controls preload="none" :poster="contentData.coverUrl"
                    :src="contentData.videoUrl" />
            </div>
            <div v-if="msgInfo.type == $enums.MESSAGE_TYPE.AUDIO">
                <audio class="quote-audio" controls :src="contentData.url"></audio>
            </div>
            <div class="quote-file" v-if="msgInfo.type == $enums.MESSAGE_TYPE.FILE">
                <div class="quote-file-info">
                    <el-link class="quote-file-name" :underline="true" target="_blank" type="primary"
                        :href="contentData.url" :download="contentData.name">{{ contentData.name }}</el-link>
                    <div class="quote-file-size">{{ fileSize }}</div>
                </div>
                <div class="quote-file-icon">
                    <span type="primary" class="el-icon-document"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "chatQuoteMessage",
    props: {
        showName: {
            type: String,
            required: true
        },
        msgInfo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {

        }
    },
	methods:{
		showFullImageBox() {
			let imageUrl = this.contentData.originUrl;
			if (imageUrl) {
				this.$store.commit('showFullImageBox', imageUrl);
			}
		},
	},
    computed: {
        quoteMessageText() {
            return this.msgInfo.content + "hello";
        },
        contentData() {
            return JSON.parse(this.msgInfo.content)
        },
        fileSize() {
            let size = this.contentData.size;
            if (size > 1024 * 1024) {
                return Math.round(size / 1024 / 1024) + "M";
            }
            if (size > 1024) {
                return Math.round(size / 1024) + "KB";
            }
            return size + "B";
        },
		isRecall(){
			return this.msgInfo.status == this.$enums.MESSAGE_STATUS.RECALL
		}
    }
}
</script>



<style lang="scss">
.chat-quote-message {
    background: #eee;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    font-size: var(--im-font-size-smaller);
    color: var(--im-text-color-light);

    .send-user {
        margin-right: 10px;
        font-weight: 600;
    }

    .quote-content {
        .quote-image {
            min-width: 40px;
            min-height: 30px;
            max-width: 80px;
            max-height: 60px;
            cursor: pointer;
        }

        .quote-video {
            min-width: 80px;
            min-height: 60px;
            max-width: 160px;
            max-height: 120px;
            cursor: pointer;
        }

        .quote-audio {
            height: 38px;
        }


        .quote-file {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            padding: 2px 15px;

            .quote-file-info {
                flex: 1;
                height: 100%;
                text-align: left;
                font-size: 14px;
                margin-right: 10px;

                .quote-file-name {
                    display: inline-block;
                    min-width: 160px;
                    max-width: 220px;
                    font-size: 14px;
                    margin-bottom: 4px;
                    white-space: pre-wrap;
                    word-break: break-all;
                }

                .quote-file-size {
                    font-size: var(--im-font-size-smaller);
                    color: var(--im-text-color-light);
                }
            }

            .quote-file-icon {
                font-size: 32px;
                color: #d42e07;
            }

        }
    }
}
</style>